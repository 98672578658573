import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { createClient } from '@supabase/supabase-js';
import { Button, Input } from '@pancakeswap/uikit';
import { ethers } from 'ethers'; // Hozzáadjuk az ethers könyvtárat
import contractABI from './contractABI.json'; // Hozzáadjuk az ABI-t

// Supabase inicializálás
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

// Contract cím
const contractAddress = '0x674e3eDc898Aa4b486Bd2E5aa2D1C8653677F471';

// Stílusok
const CountdownContainer = styled.div`
  font-size: 22px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
  background-color: #0d0d0d;
  padding: 15px;
  border-radius: 10px;
  border: 2px solid #ffffff;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div:first-child {
    margin-bottom: 10px;
  }
`;

const RewardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  color: #ffffff;
`;

const RewardItem = styled.div`
  font-size: 18px;
  margin: 0 15px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ffffff;
  background-color: #0d0d0d;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
`;

const UserItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #1a1a1a;
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: translateY(-5px);
  }
`;

const UserName = styled.span.withConfig({
  shouldForwardProp: (prop) => prop !== 'rank',
})`
  font-size: 18px;
  font-weight: bold;
  color: ${({ rank }) =>
    rank === 1 ? 'gold' :
    rank === 2 ? 'silver' :
    rank === 3 ? '#cd7f32' : '#ffffff'};
`;

const UserScore = styled.span`
  font-size: 18px;
  color: #ffffff;
`;

const Container = styled.div`
  padding: 30px;
  max-width: 900px;
  margin: 0 auto;
  background-color: #0d0d0d;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
`;

const TopListTitle = styled.h2`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
  color: #000;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
`;

const StyledInput = styled(Input)`
  margin-bottom: 20px;
  background-color: #000;
  color: #ffffff;
  border: 1px solid #ffffff;
  
  &::placeholder {
    color: #ffffff;
  }
`;

const Notification = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  margin-top: 20px;
`;

const RegisterButton = styled(Button)`
  margin-top: 10px;
  background-color: #ffffff;
  color: #0d0d0d;
`;

// Contract inicializálása
const getContract = () => {
  const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org');
  return new ethers.Contract(contractAddress, contractABI, provider);
};

// Felhasználói név lekérése a contractról
const fetchUserNameFromContract = async (account) => {
  try {
    const contract = getContract();
    const name = await contract.getName(account); // Lekérjük a nevet a contractból
    return name || null;
  } catch (error) {
    console.error('Hiba a név lekérésekor:', error);
    return null;
  }
};

const Toplist = () => {
  const [topList, setTopList] = useState([]);
  const [names, setNames] = useState({});
  const [userScores, setUserScores] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const fetchTopList = async () => {
      const { data, error } = await supabase
        .from('blackjack_scores')
        .select('*')
        .order('score', { ascending: false });

      if (!error) {
        setTopList(data);
      }
    };

    fetchTopList();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const newNames = {};
      const newScores = {};

      const validUsers = await Promise.all(
        topList.map(async (user) => {
          const userAccount = user.account;

          // Lekérjük a felhasználói nevet a contractból
          const userNameFromContract = await fetchUserNameFromContract(userAccount);

          // Csak akkor adjuk hozzá a listához, ha van név és pontszám
          if (userNameFromContract) {
            newNames[userAccount] = userNameFromContract;
            newScores[userAccount] = user.score;
            return user;
          }
          return null;
        })
      );

      setTopList(validUsers.filter(Boolean)); // Szűrjük ki a null értékeket
      setNames(newNames);
      setUserScores(newScores);
    };

    if (topList.length > 0) {
      fetchUserData();
    }
  }, [topList]);

  useEffect(() => {
    const targetDate = new Date('2025-02-28T00:00:00');
    const countdown = setInterval(() => {
      const now = new Date();
      const diff = targetDate - now;

      if (diff <= 0) {
        clearInterval(countdown);
        setTimeLeft('The competition has ended.');
      } else {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const filteredTopList = topList.filter((user) => {
    const userName = names[user.account]?.toLowerCase() || '';
    return userName.includes(searchQuery.toLowerCase()) || user.account.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <Container>
      <Link to="/blackjack">
        <Button style={{ backgroundColor: 'white', color: 'black' }}>&lt; Back to BlackJack</Button>
      </Link>

      <CountdownContainer>
        <div>Time left until the competition ends:</div>
        <div>{timeLeft}</div>
      </CountdownContainer>

      <RewardContainer>
        <RewardItem>
          <strong style={{ color: 'gold' }}>🏆 1st Place:</strong> 3333 VIZSLASWAP tokens
        </RewardItem>
        <RewardItem>
          <strong style={{ color: 'silver' }}>🥈 2nd Place:</strong> 2222 VIZSLASWAP tokens
        </RewardItem>
        <RewardItem>
          <strong style={{ color: '#cd7f32' }}>🥉 3rd Place:</strong> 1111 VIZSLASWAP tokens
        </RewardItem>
      </RewardContainer>

      <TopListTitle>Top List</TopListTitle>
      <StyledInput
        placeholder="Search by name or address"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
  
        <ul>
          {filteredTopList.map((user, index) => (
            <UserItem key={user.account}>
              <UserName rank={index + 1}>
                {names[user.account] || `${user.account.substring(0, 5)}...${user.account.substring(user.account.length - 5)}`}
              </UserName>
              <UserScore>{userScores[user.account]} points</UserScore>
            </UserItem>
          ))}
        </ul>
      </Container>
    );
  };
  
  export default Toplist;
  